/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SiteConfig from '../../../data/SiteConfig';
import Categories from '../Categories';
import RecentArticles from '../RecentArticles';
import BuiltSection from './builtSection';

const style = {
  height: '75px',
  width: '75px',
};

export default () => (
  <StaticQuery
    query={graphql`
      query FooterThumbnailQuery {
        thumbnail: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "juvoni-thumbnail.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 150, layout: FIXED, formats: [AUTO, WEBP])
          }
        }
        recentPosts: allMdx(
          limit: 5
          sort: { fields: frontmatter___date, order: DESC }
          filter: {
            fields: { type: { eq: "BlogPost" } }
            frontmatter: { image: { relativePath: { ne: null } } }
          }
        ) {
          totalCount
          nodes {
            frontmatter {
              title
              slug
              date(formatString: "dddd, MMMM DD, YYYY")
              author
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer
        className="pt-4 pb-0 border-t-2 border-primary-500 bg-gray-100"
        style={{
          backgroundColor: 'var(--bgFooter)',
          color: 'var(--textNormal)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
        }}
      >
        <div className="container py-6">
          <div className="md:flex md:justify-between md:items-start md:flex-wrap">
            <div className="w-full md:w-1/3">
              <Categories />
            </div>
            <div className="w-full md:w-1/3">
              <RecentArticles title="Latest" recentPosts={data.recentPosts} showDate={false} />
            </div>
            <aside className="md:text-right w-full lg:w-1/3">
              <div className="flex flex-col flex-wrap items-center">
                <GatsbyImage
                  image={data.thumbnail.childImageSharp.gatsbyImageData}
                  className="rounded-full"
                  alt="Profile"
                  style={style}
                />
                <a
                  className="text-gray-700 hover:text-blue-500 font-mono pl-3 pt-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.twitter.com/juvoni"
                  css={css`
                    color: var(--textNormal);
                  `}
                >
                  @juvoni <i className="fab fa-twitter" />
                </a>
              </div>
            </aside>
          </div>
        </div>
        <div
          className="p-0 border-t-2 border-gray-300"
          style={{
            borderColor: 'var(--bgBorder)',
          }}
        >
          <BuiltSection />
          <div className="">
            <ul className="flex justify-center py-4 text-xs font-display list-none">
              <li className="list-none pr-2">
                <span>{SiteConfig.copyright}</span>
              </li>
              <li className="font-bold list-none pr-2">
                <span>&middot;</span>
              </li>
              <li className="list-none">
                <Link to="rss.xml">RSS</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    )}
  />
);
