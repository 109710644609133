/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';

const RecentArticles = ({ recentPosts, showDate = true, title = 'Recent Articles' }) => (
  <div className="">
    <h2 className="mt-0 mb-6 text-primary-600 text-center md:text-left text-xl">{title}</h2>
    <ul>
      {recentPosts.nodes.map((post) => (
        <li className="flex flex-col w-auto" key={post.frontmatter.slug}>
          {showDate && (
            <span className="text-sm text-gray-500 font-mono">{post.frontmatter.date}</span>
          )}
          <Link
            to={post.frontmatter.slug}
            className={`hover:text-primary-600 font-display ${showDate ? 'text-base' : 'text-sm'}`}
            css={css`
              color: var(--textNormal);
            `}
          >
            {post.frontmatter.title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default RecentArticles;
