import { Link } from 'gatsby';
import React from 'react';

const Category = ({ to, children }) => (
  <li className="hover:text-blue-500 hover:underline">
    <Link to={to}>{children}</Link>
  </li>
);

const Categories = () => (
  <div>
    <h2 className="mt-0 mb-6 text-center md:text-left text-primary-600 text-xl">
      Featured Categories
    </h2>
    <ul className="font-display">
      <Category to="/category/productivity/">Productivity</Category>
      <Category to="/category/personal-finance/">Personal Finance</Category>
      <Category to="/category/personal-development">Personal Development</Category>
      <Category to="/category/tools">Workflows</Category>
    </ul>
  </div>
);

export default Categories;
