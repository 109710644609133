const config = {
  backgroundColor: '#e0e0e0',
  booksAffiliateLink: 'books-juvoni-20', // Site title.
  copyright: '© 2020 Juvoni Beckford', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  dateFormat: 'DD/MM/YYYY', // Alternative site title for SEO.
  dateFromFormat: 'YYYY-MM-DD', // Logo used for SEO and manifest.
  disqusShortname: 'https-vagr9k-github-io-gatsby-advanced-starter', // Domain of your website without pathPrefix.
  googleAnalyticsID: 'UA-37503096-1', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  name: 'Juvoni Beckford', // Website description used for RSS feeds/meta description tag.
  pathPrefix: '', // Path to the RSS file.
  personalThumbnail: './src/images/juvoni-thumbnail.jpg', // FB Application ID for using app insights
  postDefaultCategoryID: 'Tech', // GA tracking ID.
  siteDescription: 'A disciplined approach to growth.', // Disqus shortname.
  siteFBAppID: '1825356251115265', // Default category for posts.
  siteLogo: './src/images/favicon.png',
  siteRss: '/rss.xml', // Date format used in the frontmatter.
  siteTitle: 'Juvoni Beckford',
  siteTitleAlt: 'Juvoni Beckford', // Date format for display.
  siteTitleShort: 'Juvoni', // Username to display in the author segment.
  siteUrl: 'https://juvoni.com', // Email used for RSS feed's author segment
  siteUrlShort: 'juvoni.com', // Email used for RSS feed's author segment
  themeColor: '#25BB98', // Optionally renders "Follow Me" in the UserInfo segment.
  userAvatar: 'https://api.adorable.io/avatars/150/test.png', // User location to display in the author segment.
  userDescription:
    'I write about developing and acquiring habits, systems and tools for a disciplined approach to life-long learning and self-improvement. I curate Books, Technologies, Mental Models, Mindsets and Cognitive Biases for smarter and more self-aware living, seeing the world as it is, while bringing your visions to life. I aim to teach others how to become more antifragile and how to upgrade their decision making and intuition.', // User avatar to display in the author segment.
  userEmail: 'message@juvoni.com', // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      iconClassName: 'fa fa-github',
      label: 'GitHub',
      url: 'https://github.com/juvoni',
    },
    {
      iconClassName: 'fa fa-twitter',
      label: 'Twitter',
      url: 'https://twitter.com/juvoni',
    },
    {
      iconClassName: 'fa fa-envelope',
      label: 'Email',
      url: 'mailto:message@juvoni.com',
    },
  ],
  userLocation: 'New York, NY', // Copyright string for the footer of the website and RSS feed.
  userName: 'juvoni', // Used for setting manifest and progress theme colors.
  userTwitter: 'juvoni', // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = '';
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/') config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/') config.siteRss = `/${config.siteRss}`;

module.exports = config;
