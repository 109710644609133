/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';

const divider = ({ position, margin }) => {
  const positionClasses = classNames({
    'border-b': position === 'bottom',
    'border-l': position === 'left',
    'border-r': position === 'right',
    'border-t': position === 'right',
  });

  return (
    <hr
      className={`${positionClasses} my-${margin || '12'} block`}
      css={css`
        border-color: var(--border);
      `}
    />
  );
};

export default divider;
