import { Link } from 'gatsby';
import React from 'react';

const logo = ({ src }) => (
  <Link to="/" className="flex flex-shrink">
    <img
      src={src}
      style={{
        height: '35px',
        width: '165px',
      }}
      alt="Juvoni Logo"
    />
    {/* <h1 className="p-0 m-0 text-base">Juvoni Beckford</h1> */}
  </Link>
);

export default logo;
