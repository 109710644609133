import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const menu = ({ gatsbyLink, link, label }) => (
  <React.Fragment key={label}>
    {gatsbyLink ? (
      <Link
        to={link}
        className="font-sans uppercase hover:text-primary-600 tracking-wide text-sm   mr-4 last:mr-0 my-2 inline-block"
      >
        {label}
      </Link>
    ) : (
      <a
        href={link}
        className="font-sans uppercase hover:text-primary-600 tracking-wide text-sm  mr-4 last:mr-0 my-2 inline-block"
      >
        {label}
      </a>
    )}
  </React.Fragment>
);

menu.propTypes = {
  gatsbyLink: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
};

export default menu;
