/*
 *  gatsbyLink if true will use GatsbyLink and if false will use a tag for the link
 */

export default {
  header: [
    {
      gatsbyLink: true,
      label: 'Bookshelf',
      link: '/books',
    },
    {
      gatsbyLink: true,
      label: 'Notes',
      link: '/notes',
    },
    {
      gatsbyLink: true,
      label: 'Essays',
      link: '/category/essays',
    },
    {
      gatsbyLink: true,
      label: 'About',
      link: '/about',
    },
    {
      gatsbyLink: true,
      label: 'Archive',
      link: '/archive',
    },
  ],
};
