import React from 'react';
import CookieConsent from 'react-cookie-consent';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';

const config = resolveConfig(tailwindConfig);

const {
  theme: {
    colors: { primary, yellow },
  },
} = config;

function AlertDismissible() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Ok"
      cookieName="juvoniSiteMigrationAlert"
      style={{ background: primary[500] }}
      buttonClasses="font-display"
      buttonStyle={{
        borderRadius: '8px',
        color: yellow,
        fontSize: '16px',
      }}
      expires={3}
    >
      <div className="text-center">
        <p className="font-display text-lg font-semibold text-white">Early Beta Notice</p>
        <p className="hidden md:block font-sans text-white">
          You are now viewing an early preview of the <b>new juvoni.com</b>. The site is under
          active development and some content might be out of date or certain sections incomplete or
          broken. <b>You should still find some hidden gems or inspiration. 🙏</b>
        </p>
        <p className="block md:hidden font-sans text-white">
          You're viewing an early preview of my new site relaunch. All new features and content will
          be ready in a couple weeks.
        </p>
      </div>
    </CookieConsent>
  );
}

export const HeaderNotification = () => <AlertDismissible />;

export default HeaderNotification;
