/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import { css, Global, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import throttle from 'lodash.throttle';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import Menus from '../data/menu';
import useKeyboardShortcut from '../hooks/use-keyboard-shortcut';
import LogoImgDark from '../images/juvoni-v4-dark.svg';
import LogoImg from '../images/juvoni-v4.svg';
import ThemeContext from '../providers/ThemeContext';
import useWindowEvent from './hooks/useWindowEvent';
import FooterSection from './template/footer/footer';
import { Logo, MenuItem } from './ui';
import Pill from './ui/badge/pill';
import DarkModeToggle from './ui/dark/dark';
import { HeaderNotification } from './ui/notification/header-notification';
import Search from './ui/search/search';

export default function Layout({ children, progress, sticky }) {
  const [isSecondarySearchOpen, setSecondarySearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isPrimarySearchOpen, setPrimarySearch] = useState(false);
  const MenuRef = useRef(null);
  let oldScrollPosition = 0;

  function focusSearch() {
    const searchBox = document.getElementById('searchbox');
    searchBox.focus();
  }

  function handleSearch() {
    setPrimarySearch((state) => !state);
    focusSearch();
  }

  const handleSlashKey = useCallback(() => {
    if (!isPrimarySearchOpen) {
      setPrimarySearch(true);
      focusSearch();
    }
  }, [setPrimarySearch, isPrimarySearchOpen]);

  const handleEscapeKey = useCallback(() => {
    setSearchTerm('');
    setPrimarySearch(false);
  }, [setPrimarySearch]);

  useKeyboardShortcut(['/'], handleSlashKey);
  useKeyboardShortcut(['escape'], handleEscapeKey);

  function closeSearch() {
    setPrimarySearch(false);
  }

  useEffect(() => {
    if (isSecondarySearchOpen) {
      closeSearch();
    }
  }, [isSecondarySearchOpen]);

  function close(e) {
    if (
      e.target.tagName === 'A' ||
      (e.target.id !== 'menu-toggle' && MenuRef.current && !MenuRef.current.contains(e.target))
    ) {
      setSecondarySearchOpen(false);
    }
  }

  function togglesetSecondarySearchOpen() {
    setSecondarySearchOpen((o) => !o);
  }

  const handleSticky = throttle(() => {
    const bar = document.getElementById('navbar');
    const spacer = document.getElementById('spacer');
    const setFixed = () => {
      bar.classList.add('shadow-lg');
      bar.classList.add('fixed');
      bar.classList.add('raised');
      spacer.classList.add('block');
      spacer.classList.remove('hidden');
    };
    const removeFixed = () => {
      bar.classList.remove('shadow-lg');
      bar.classList.remove('fixed');
      bar.classList.remove('raised');
      spacer.classList.remove('block');
      spacer.classList.add('hidden');
    };
    const scrollPosition = Math.round(window.scrollY);

    if (sticky) {
      if (scrollPosition > 400) {
        setFixed();
      } else {
        removeFixed();
      }
    } else if (oldScrollPosition > scrollPosition && scrollPosition !== 0) {
      setFixed();
    } else {
      removeFixed();
    }
    oldScrollPosition = scrollPosition;
  }, 300);

  useWindowEvent('scroll', handleSticky);

  useEffect(() => {
    handleSticky();
    document.addEventListener('click', close);
    return () => {
      document.removeEventListener('click', close);
    };
  }, [handleSticky, isSecondarySearchOpen]);

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div className={`flex flex-col min-h-screen ${theme.dark ? 'dark' : 'light'}`}>
          <Global
            styles={css`
              body {
                overflow: ${isPrimarySearchOpen && searchTerm.trim() !== '' ? 'hidden' : 'auto'};
              }
              #gatsby-plugin-page-progress {
                z-index: ${progress ? '1000 !important' : '0'};
              }
            `}
          />
          <HeaderNotification />
          <div
            id="spacer"
            className="hidden"
            css={css`
              height: 67px;
              width: 100%;
              transition: height 300ms ease-in;
            `}
          />
          <div
            id="navbar"
            className="top-0 w-screen flex items-center"
            css={css`
              min-height: 67px;
              transition: height 300ms ease-in;
              z-index: 900;
              background-color: var(--bg);
              color: var(--textNormal);
            `}
          >
            <div className="container py-3 flex justify-between items-center">
              <Logo src={theme.dark ? LogoImgDark : LogoImg} />
              <div className="flex items-center">
                <div
                  className="text-gray-600 lg:block hidden"
                  style={{
                    color: 'var(--textNormal)',
                    transition: 'color 0.2s ease-out, background 0.2s ease-out',
                  }}
                >
                  {Menus.header.map(({ gatsbyLink, link, label }) => (
                    <MenuItem gatsbyLink={gatsbyLink} link={link} label={label} key={label} />
                  ))}
                  <button
                    type="button"
                    onClick={handleSearch}
                    className="font-bold cursor-pointer rounded tracking-tighter py-2 pl-3 pr-4 mr-2 text-base  leading-none text-gray-600 hover:text-primary-500"
                    css={css`
                      background-color: var(--bgInput);
                    `}
                  >
                    <span className="flex justify-center">
                      <i className="fa fa-search mr-3" />
                      <span className="font-sans text-sm uppercase">
                        Search
                        <span
                          className="font-mono text-xs ml-3 border rounded opacity-75"
                          css={css`
                            border-color: var(--bgOutline);
                            color: var(--bgInputDarker);
                            padding: 1px 6px 2px 5px;
                          `}
                        >
                          /
                        </span>
                      </span>
                    </span>
                  </button>
                </div>
                <div className="block lg:hidden flex">
                  <button
                    type="button"
                    onClick={handleSearch}
                    className="font-bold inline-block mr-2  cursor-pointer tracking-tighter p-2 text-base  leading-none text-white rounded-sm"
                    css={css`
                      color: var(--textLabel);
                    `}
                  >
                    <i className="fa fa-search pointer-events-none" />
                  </button>
                  <span
                    onClick={togglesetSecondarySearchOpen}
                    id="menu-toggle"
                    className="font-bold block cursor-pointer tracking-tighter p-2 text-base  leading-none text-white rounded-sm"
                    css={css`
                      color: var(--textLabel);
                    `}
                  >
                    <i className="fas fa-bars pointer-events-none" />
                  </span>
                </div>
                <DarkModeToggle />
              </div>
            </div>
          </div>
          <div
            className="text-gray-200 bg-gray-800 "
            ref={MenuRef}
            css={css`
              z-index: 50;
              top: 67px;
              left: 0;
              width: 100%;
              background-color: var(--bgSearch);

              .ReactCollapse--collapse {
                transition: height 300ms;
              }
            `}
          >
            <Collapse isOpened={isPrimarySearchOpen}>
              <div className="flex flex-col">
                <Search close={setPrimarySearch} isOpened={isPrimarySearchOpen} />
              </div>
            </Collapse>
          </div>{' '}
          <div
            className="text-white bg-gray-800"
            ref={MenuRef}
            css={css`
              position: fixed;
              z-index: 50;
              top: 67px;
              left: 0;
              width: 100%;
              .ReactCollapse--collapse {
                transition: height 300ms;
              }
            `}
          >
            <Collapse isOpened={isSecondarySearchOpen}>
              <div className="container flex flex-col items-center py-6">
                {' '}
                {Menus.header.map((item) => (
                  <React.Fragment key={item.label}>
                    {item.gatsbyLink ? (
                      <Link
                        to={item.link}
                        className="font-display uppercase hover:text-primary-400 tracking-wide  text-sm my-2"
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <a
                        href={item.link}
                        className="uppercase hover:text-primary-400 tracking-wide text-sm my-2"
                      >
                        {item.label}
                      </a>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Collapse>
          </div>{' '}
          <div
            css={css`
              flex-grow: 2;
            `}
          >
            {children}
          </div>
          <Pill />
          <FooterSection />
        </div>
      )}
    </ThemeContext.Consumer>
  );
}
