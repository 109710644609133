/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import algoliasearch from 'algoliasearch/lite';
import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import {
  connectCurrentRefinements,
  connectHits,
  connectSearchBox,
  connectStateResults,
  Highlight,
  InstantSearch,
} from 'react-instantsearch-dom';
import { useTabIndex } from 'react-tabindex';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';
import esc from '../../../images/icons/esc.svg';
import algoliaLogo from '../../../images/logos/search-by-algolia.svg';
import Divider from '../divider/divider';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
);

const config = resolveConfig(tailwindConfig);
const {
  theme: {
    colors: { primary },
  },
} = config;

const logoSize = {
  height: '20px',
  width: '120px',
};
const iconSize = {
  width: '21px',
};
const RESULT_LIMIT = 8;
const searchInputRef = React.createRef();
const hitRefs = Array.from(Array(RESULT_LIMIT).keys()).map(() => React.createRef());

function handleNavigateDown(e) {
  if (e.key === 'ArrowDown') {
    e.preventDefault();
    const currentResult = e.target;
    const resultContainer = currentResult.parentElement;
    const nextResultContainer = resultContainer.nextElementSibling;
    if (nextResultContainer) {
      const nextResult = nextResultContainer.getElementsByTagName('a')[0];
      nextResult.focus();
    }
  }
}
function handleNavigateUp(e) {
  if (e.key === 'ArrowUp') {
    e.preventDefault();
    const currentResult = e.target;
    const resultContainer = currentResult.parentElement;
    const previousResultContainer = resultContainer.previousElementSibling;
    if (previousResultContainer) {
      const previous = previousResultContainer.getElementsByTagName('a')[0];
      previous.focus();
    } else {
      searchInputRef.current.focus();
    }
  }
}

function handleSearchNavigation(e) {
  if (e.key === 'ArrowDown') {
    e.preventDefault();
    const { current: firstHit } = hitRefs[0] || {};

    if (firstHit) {
      firstHit.focus();
    }
  }
}

function handleResultEnter(e) {
  if (e.key === 'Enter') {
    e.target.click();
  }
}

const Hits = connectHits(({ hits }) => (
  <div className="flex flex-col">
    {hits.length ? (
      <>
        <div
          className="text-gray-800 pb-2 flex-grow w-full overflow-y-auto shadow-md"
          css={css`
            max-height: calc(100vh - 67px - 81px);
            background-color: var(--bg);
          `}
        >
          <div className="container">
            {hits.slice(0, RESULT_LIMIT).map((hit, index) => {
              const tabIndex = useTabIndex();
              return (
                <div
                  className="flex my-2"
                  key={hit.objectID}
                  css={css`
                    &:last-of-type {
                      border-bottom: none;
                    }
                  `}
                >
                  <Link
                    className="w-full p-4 rounded hover:shadow-sm"
                    tabIndex={tabIndex}
                    to={hit.slug}
                    ref={hitRefs[index]}
                    onKeyDown={handleNavigateDown}
                    onKeyUp={handleNavigateUp}
                    onKeyPress={handleResultEnter}
                    css={css`
                      color: var(--textNormal);
                      &:hover,
                      &:focus {
                        background: var(--searchHoverBg);
                      }
                    `}
                  >
                    <h4 className="m-0 text-left">
                      <span
                        className={`${
                          hit.layout === 'book' ? 'inline-flex' : 'hidden'
                        } rounded px-2 py-1 mr-2 text-sm font-mono`}
                        css={css`
                          color: var(--textNormal);
                          background: var(--searchLabelBook);
                        `}
                      >
                        Book
                      </span>
                      <span
                        className={`${
                          hit.layout.includes('post') ? 'inline-flex' : 'hidden'
                        } rounded px-2 py-1 mr-2 bg-gray-300 text-sm font-mono`}
                        css={css`
                          color: var(--textNormal);
                          background: var(--searchLabelPost);
                        `}
                      >
                        Post
                      </span>
                      <span
                        className={`${
                          hit.layout.includes('page') ? 'inline-flex' : 'hidden'
                        } rounded px-2 py-1 mr-2 bg-blue-300 text-sm font-mono`}
                        css={css`
                          color: var(--textNormal);
                          background: var(--searchLabelPage);
                        `}
                      >
                        Page
                      </span>
                      <Highlight attribute="title" hit={hit} tagName="strong" />
                    </h4>
                    {hit.subtitle ? (
                      <h5 className="mb-0">
                        <Highlight attribute="subtitle" hit={hit} tagName="strong" />
                      </h5>
                    ) : null}
                    {hit.excerpt ? (
                      <div
                        className="mt-2"
                        css={css`
                          color: var(--textNormal);
                        `}
                      >
                        <Highlight attribute="excerpt" hit={hit} tagName="strong" />
                      </div>
                    ) : null}
                    {hit.categories ? (
                      <div
                        className="font-sans text-sm mt-1"
                        css={css`
                          color: var(--textCaption);
                        `}
                      >
                        <Highlight attribute="categories" hit={hit} tagName="strong" />
                      </div>
                    ) : null}
                  </Link>
                </div>
              );
            })}
          </div>
          <Divider position="bottom" margin={4} className="w-2/3" />
          <div className="container flex justify-between items-center pb-1">
            <div>
              <span className="px-2">
                <span className="pr-2">
                  <i className="fas fa-exchange-alt fa-rotate-90 text-gray-600" />
                </span>
                <span
                  className="font-mono text-sm pl-1"
                  css={css`
                    color: var(--textCaption);
                  `}
                >
                  navigate
                </span>
              </span>
              <span className="px-2">
                <span className="pr-2">
                  <i className="fas fa-level-down-alt fa-rotate-90 text-gray-600" />
                </span>
                <span
                  className="font-mono text-sm pl-1"
                  css={css`
                    color: var(--textCaption);
                  `}
                >
                  go
                </span>
              </span>
              <span>
                <SVG
                  cacheRequests
                  description="Escape to close"
                  src={esc}
                  style={iconSize}
                  className="inline-flex"
                  title="Escape"
                />
                <span
                  className="font-mono text-sm pl-1"
                  css={css`
                    color: var(--textCaption);
                  `}
                >
                  exit
                </span>
              </span>
            </div>
            <div>
              <SVG
                cacheRequests
                description="Search by algolia"
                src={algoliaLogo}
                style={logoSize}
                title="Search by algolia"
                className="mt-2"
              />
            </div>
          </div>
        </div>
      </>
    ) : (
      <div className="flex justify-center font-display">
        <p>There were no results for your query. Please try again.</p>
      </div>
    )}
  </div>
));

const Results = connectStateResults(({ searchResults }) =>
  searchResults && searchResults.query && searchResults.query.length > 0 ? (
    <>
      <Hits />
    </>
  ) : null,
);

export default function Search({ close, isOpened }) {
  useEffect(() => {
    if (isOpened) {
      const searchBox = document.getElementById('searchbox');
      searchBox.focus();
    }
  }, [isOpened]);

  const CustomSearchBox = connectSearchBox(({ currentRefinement, refine }) => {
    const CustomClearRefinements = connectCurrentRefinements(({ items, refine: Refine }) => {
      function handleClose() {
        Refine(items);
        close();
      }
      return (
        // eslint-disable-next-line react/button-has-type
        <button className="px-2 cursor-pointer text-gray-600" onClick={handleClose}>
          <i className="fas fa-times pointer-events-none" />
        </button>
      );
    });

    return (
      <div className="container flex items-center justify-between">
        <input
          value={currentRefinement}
          onChange={(event) => refine(event.currentTarget.value)}
          onKeyDown={handleSearchNavigation}
          type="text"
          ref={searchInputRef}
          id="searchbox"
          className="font-sans text-xl bg-transparent focus:outline-none pb-1 w-5/6"
          placeholder="Search the site..."
          css={css`
            color: var(--textTitle);
            caret-color: ${primary[800]};
          `}
        />
        <CustomClearRefinements clearsQuery />
      </div>
    );
  });

  return (
    <InstantSearch indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME} searchClient={searchClient}>
      <div className="container flex items-center justify-between py-2 md:py-6">
        <CustomSearchBox close={close} />
      </div>
      <Results />
    </InstantSearch>
  );
}
