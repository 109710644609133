/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

const Card = ({ prev, next, title, slug, src, sharpFluid }) => {
  const containerClasses = classNames({
    'flex-row ': prev,
    'flex-row-reverse': next,
  });

  const imageClasses = classNames({
    'rounded-l': prev,
    'rounded-r': next,
  });

  const contentClasses = classNames({
    'items-end mr-4 text-right': prev,
    'items-start ml-4 text-left': next,
  });

  const imageDimensions = {
    height: '136px',
    width: '90px',
  };

  return (
    <Link to={slug}>
      <div
        className={`flex ${containerClasses} w-84 h-32 rounded border border-solid border-gray-200 shadow hover:shadow-lg items-center justify-between mb-4`}
        css={css`
          background-color: var(--bgRaised);
          color: var(--textNormal);
          border: var(--border);
        `}
      >
        {src && (
          <img
            className={`${imageClasses} bg-white`}
            style={imageDimensions}
            alt={`${title} Cover`}
            src={src}
          />
        )}
        {sharpFluid && (
          <GatsbyImage
            image={sharpFluid}
            className={`${imageClasses} bg-white`}
            style={imageDimensions}
            alt={`${title} Cover`}
          />
        )}
        <div className={`flex flex-col ${contentClasses} h-24 w-3/5 justify-start`}>
          <span className="font-mono text-xs text-gray-500 pb-3">{prev ? 'Previous' : 'Next'}</span>
          <span className="font-display text-sm text-gray-600">
            {title.length > 75 ? `${title.substring(0, 75)} ...` : title}
          </span>
        </div>
      </div>
    </Link>
  );
};

export const TextCard = ({ prev, next, title, slug }) => {
  const containerClasses = classNames({
    'flex-row ': prev,
    'flex-row-reverse': next,
  });

  const typeContainerClasses = classNames({
    'ml-8': prev,
    'text-right mr-8': next,
  });

  const contentClasses = classNames({
    'ml-4': next,
    'mr-4': prev,
  });

  const titleContainerClasses = classNames({
    '': prev,
    'flex-row-reverse text-right': next,
  });

  const icon = prev ? 'fa-arrow-left' : 'fa-arrow-right';

  return (
    <Link to={`${slug}`}>
      <div className={`flex ${containerClasses} w-84 h-32 items-center justify-between mb-4`}>
        <div className="flex flex-col h-24 w-3/5 justify-start">
          <span className={`font-mono text-sm text-gray-500 pb-3 ${typeContainerClasses}`}>
            {prev ? 'Previous' : 'Next'}
          </span>
          <div className={`flex ${titleContainerClasses}`}>
            <i className={`fa ${icon} ${contentClasses} fa-lg text-gray-600`} />
            <span className="font-display text-sm text-gray-600">
              {title.length > 75 ? `${title.substring(0, 75)} ...` : title}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

Card.propTypes = {
  next: PropTypes.bool,
  prev: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sharpFluid: PropTypes.object,
  slug: PropTypes.string.isRequired,
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Card.defaultProps = {
  next: false,
  prev: false,
  sharpFluid: null,
  src: null,
};

export default Card;
