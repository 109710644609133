/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import ReactTooltip from 'react-tooltip';

const Pill = () => (
  <div
    className="hidden md:fixed md:inline-block w-32 p-2 bg-primary-300 rounded-tl rounded-tr mr-2 text-center font-display"
    css={css`
      bottom: 0;
      right: 0;
    `}
  >
    <span
      className="text-white text-sm"
      data-tip="You are viewing an early release of my upgraded site and some sections are still being updated and fixed."
      data-for="early-preview"
      data-effect="solid"
    >
      Early Preview
    </span>
    <ReactTooltip id="early-preview" />
  </div>
);

export default Pill;
