import React from 'react';
import SVG from 'react-inlinesvg';
import gatsby from '../../../images/logos/gatsby.svg';
import netlify from '../../../images/logos/netlify.svg';
import tailwind from '../../../images/logos/tailwind.svg';

const BuiltSection = () => (
  <div className="container pt-1 flex flex-col md:flex-row justify-center items-center">
    <span className="w-full md:w-1/3 pt-2 text-xs font-display">
      <span className="font-mono text-xs">
        Site Developed & Designed with{' '}
        <span role="img" aria-label="heart">
          🖤
        </span>{' '}
        by Juvoni
      </span>
    </span>
    <span className="pt-2 text-xs font-display">
      <span className="font-mono text-xs">
        <span role="img" aria-label="construction">
          🏗
        </span>{' '}
        built with
      </span>
    </span>
    <ul className="flex flex-row pt-2">
      <li className="list-none pr-2">
        <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">
          <SVG cacheRequests description="Gatsby" src={gatsby} className="" title="Gatsby" />
        </a>
      </li>
      <li className="list-none pr-2">
        {' '}
        <a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">
          {' '}
          <SVG cacheRequests description="Netlify" src={netlify} className="" title="Netlify" />
        </a>
      </li>
      <li className="list-none pr-2">
        <a href="https://tailwindcss.com/" target="_blank" rel="noopener noreferrer">
          {' '}
          <SVG
            cacheRequests
            description="TailwindCSS"
            src={tailwind}
            className=""
            title="TailwindCSS"
          />
        </a>
      </li>
    </ul>
  </div>
);

export default BuiltSection;
